












import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
import Storage from "@/core/utils/LocalStorage";

@Component
export default class DarkModeSwitch extends Vue {
  @Prop({ default: () => true }) showLabel!: boolean;
  @Watch("$vuetify.theme.dark")
  toggleMode(mode: boolean) {
    Storage.setDarkMode(mode);
  }

  get label() {
    if (this.showLabel) return "Dark Mode";
    return "";
  }
}
